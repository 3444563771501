import { useAuth } from '@frontegg/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { useNotification } from 'reablocks';
import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { api } from './api';
export const QueryProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const { notifyError } = useNotification();

  const client = useMemo(() => {
    const queryClient = new QueryClient({
      queryCache: new QueryCache({
        // https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#defining-on-demand-messages
        onError: (_error, query) => {
          notifyError(
            (query?.meta?.errorMessage as string) ??
              'An error occurred, please try again later.'
          );
        }
      }),
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false
        },
        mutations: {
          retry: false,
          onError: (error: any) => {
            // TODO: Handle w/ notification
            console.error(`Mutation error: ${error.message}`);
          }
        }
      }
    });

    return queryClient;
  }, []);

  useEffect(() => {
    if (user) {
      // This isn't the best way to handle this but it prevents
      // the interceptor from always having to retry once
      api.defaults.headers['X-API-KEY'] = user.accessToken;

      createAuthRefreshInterceptor(api, failedRequest => {
        return new Promise((resolve, _reject) => {
          failedRequest.response.config.headers['X-API-KEY'] = user.accessToken;
          resolve(user.accessToken);
        });
      });
    }
  }, [user]);

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};
