import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  cn,
  DateFormat,
  Dialog,
  IconButton,
  SecondaryHeading,
  Stack,
  Text
} from 'reablocks';
import { QueryObserverResult, UseMutateFunction } from '@tanstack/react-query';
import { Reorder } from 'framer-motion';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as FilledStarIcon } from 'assets/icons/star-filed.svg';
import { OnboardButton } from 'shared/elements/OnboardButton';
import { TrendingPrompt } from 'core/api';

export interface TrendingPromptsDialogProps {
  open: boolean;
  onCancel: () => void;
  className?: string;
  trendingPrompts?: Array<TrendingPrompt>;
  onUpdateMutation: UseMutateFunction<
    TrendingPrompt,
    Error,
    TrendingPrompt,
    unknown
  >;
  onDeleteMutation: UseMutateFunction<void, Error, string, unknown>;
  onSaveListMutation: UseMutateFunction<void, Error, TrendingPrompt[], unknown>;
  onRefetchPrompts: () => Promise<QueryObserverResult<TrendingPrompt[], Error>>;
  onAddCLick: () => void;
  isSaving: boolean;
}

export const TrendingPromptsDialog: FC<TrendingPromptsDialogProps> = ({
  open,
  className,
  onCancel,
  trendingPrompts,
  onUpdateMutation,
  onDeleteMutation,
  onSaveListMutation,
  onRefetchPrompts,
  onAddCLick,
  isSaving
}) => {
  const [prompts, setPrompts] =
    useState<Array<TrendingPrompt>>(trendingPrompts);

  useEffect(() => {
    setPrompts(trendingPrompts);
  }, [trendingPrompts]);

  const onClear = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onDelete = useCallback(
    (id: string) => {
      onDeleteMutation(id, {
        onSuccess: onRefetchPrompts
      });
    },
    [onDeleteMutation, onRefetchPrompts]
  );

  const onFavor = useCallback(
    (prompt: TrendingPrompt) => {
      const newPrompt: TrendingPrompt = {
        ...prompt,
        favorited: !prompt.favorited
      };

      onUpdateMutation(newPrompt, {
        onSuccess: onRefetchPrompts
      });
    },
    [onRefetchPrompts, onUpdateMutation]
  );

  const onSaveList = useCallback(() => {
    onSaveListMutation(prompts, {
      onSuccess: onRefetchPrompts
    });
  }, [prompts, onRefetchPrompts, onSaveListMutation]);

  const handleReorder = useCallback((newOrder: Array<TrendingPrompt>) => {
    const updatedPrompts = newOrder.map(item => ({
      ...item,
      updatedAt: new Date()
    }));
    setPrompts(updatedPrompts);
  }, []);

  return (
    <Dialog
      size="780px"
      header={
        <SecondaryHeading className="text-lg font-bold leading-6">
          Manage Trending Prompts
        </SecondaryHeading>
      }
      open={open}
      closeOnBackdropClick={false}
      closeOnEscape={false}
      showCloseButton={true}
      onClose={onClear}
      innerClassName={cn(
        'relative bg-gray-700/[0.8] border border-gray-200 rounded-[10px]',
        className
      )}
    >
      {() => (
        <div className="p-4 pl-0 pb-16">
          <>
            <Stack
              direction="row"
              justifyContent="spaceBetween"
              className="mb-6"
            >
              <Text className="text-gray-100 text-[15px] font-semibold">
                Prompts{' '}
                <span className="font-normal">
                  [{trendingPrompts?.length ?? 0}]{' '}
                </span>
              </Text>
              <Button
                variant="text"
                className="text-primary-100 text-[15px] font-normal"
                onClick={onAddCLick}
              >
                + Add Prompt
              </Button>
            </Stack>

            <Stack direction="column" className="w-full">
              <Reorder.Group
                className="w-full"
                values={prompts}
                onReorder={handleReorder}
              >
                {prompts.map(item => (
                  <Reorder.Item key={item.id} value={item}>
                    <Card
                      className="p-5 border border-gray-500 bg-gray-200/[0.10] rounded-md mb-2.5"
                      key={item.id}
                    >
                      <Stack direction="row">
                        <IconButton variant="text">
                          <DragIcon />
                        </IconButton>

                        <Stack
                          className="w-full"
                          direction="column"
                          justifyContent="start"
                          alignItems="start"
                        >
                          <Text className="text-[13px] text-white font-normal">
                            {item.prompt}
                          </Text>
                          <Stack direction="row">
                            {item?.owner?.name && (
                              <>
                                <Text className="text-xs font-normal text-gray-100">
                                  {item?.owner?.name}
                                </Text>
                                •
                              </>
                            )}
                            {item.updatedAt && (
                              <DateFormat
                                className="text-xs font-normal text-gray-100"
                                format="MMMM dd, yyyy"
                                date={item.updatedAt}
                              />
                            )}
                          </Stack>
                        </Stack>

                        <Stack direction="row" className="self-start">
                          <IconButton
                            disabled={isSaving}
                            className="self-start p-1"
                            variant="text"
                            onClick={() => onFavor(item)}
                          >
                            {item?.favorited ? (
                              <FilledStarIcon />
                            ) : (
                              <StarIcon />
                            )}
                          </IconButton>

                          <IconButton
                            disabled={isSaving}
                            className="self-start p-1"
                            variant="text"
                            onClick={() => onDelete(item.id)}
                          >
                            <DeleteIcon className="[&_path]:fill-gray-100" />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </Card>
                  </Reorder.Item>
                ))}
              </Reorder.Group>
            </Stack>

            <Stack
              className="w-full absolute right-8 bottom-0 bg-gray-700/[0.8] py-4"
              direction="row"
              justifyContent="end"
            >
              <OnboardButton
                disabled={isSaving}
                className="!border-gray-200 py-4 px-10 m-0"
                hideArrow={true}
                onClick={onClear}
              >
                Close
              </OnboardButton>
              <OnboardButton
                disabled={isSaving}
                className="py-4 px-10 m-0"
                hideArrow={true}
                onClick={onSaveList}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </OnboardButton>
            </Stack>
          </>
        </div>
      )}
    </Dialog>
  );
};
