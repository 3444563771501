import React, { FC, useCallback, useState } from 'react';
import {
  cn,
  Dialog,
  IconButton,
  SecondaryHeading,
  Stack,
  Text,
  Textarea
} from 'reablocks';
import { QueryObserverResult, UseMutateFunction } from '@tanstack/react-query';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { OnboardButton } from 'shared/elements/OnboardButton';
import { TrendingPrompt } from 'core/api';

export interface TrendingPromptsDialogProps {
  open: boolean;
  onCancel: () => void;
  className?: string;
  onCreateMutation: UseMutateFunction<
    TrendingPrompt,
    Error,
    TrendingPrompt,
    unknown
  >;
  refetchPrompts: () => Promise<QueryObserverResult<TrendingPrompt[], Error>>;
  isSaving: boolean;
}

export const TrendingPromptsCreateDialog: FC<TrendingPromptsDialogProps> = ({
  open,
  className,
  onCancel,
  onCreateMutation,
  refetchPrompts,
  isSaving
}) => {
  const [inputVal, setInputVal] = useState<string>('');

  const onClear = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSubmit = useCallback(() => {
    if (!inputVal?.trim()) {
      return;
    }

    const newPrompt: TrendingPrompt = {
      prompt: inputVal,
      createdAt: new Date(),
      updatedAt: new Date()
    };

    onCreateMutation(newPrompt, {
      onSuccess: async () => {
        await refetchPrompts();
        setInputVal('');
        onCancel();
      }
    });
  }, [onCreateMutation, inputVal, onCancel, refetchPrompts]);

  return (
    <Dialog
      size="780px"
      header={
        <SecondaryHeading className="text-lg font-bold leading-6">
          Add Trending Prompt
        </SecondaryHeading>
      }
      open={open}
      closeOnBackdropClick={false}
      closeOnEscape={false}
      showCloseButton={true}
      onClose={onClear}
      innerClassName={cn(
        'relative bg-gray-700/[0.8] border border-gray-200 rounded-[10px]',
        className
      )}
    >
      {() => (
        <form onSubmit={handleSubmit}>
          <div className="p-4 pl-0">
            <>
              <IconButton
                className="pl-0 mb-4"
                variant="text"
                onClick={onCancel}
              >
                <ArrowLeftIcon />
                <Text className="text-gray-100 text-[15px] font-semibold">
                  Back
                </Text>
              </IconButton>
              <Textarea
                disabled={isSaving}
                containerClassName="border-0 mb-10"
                className={cn(
                  'main-input placeholder:text-gray-100/50 outline-offset-0'
                )}
                rows={3}
                autoFocus
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                placeholder="Add prompt..."
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
                value={inputVal}
                onChange={e => setInputVal(e.target.value)}
              />
              <Stack direction="row" justifyContent="end">
                <OnboardButton
                  disabled={isSaving}
                  className="!border-gray-200 py-4 px-10"
                  hideArrow={true}
                  onClick={onCancel}
                >
                  Close
                </OnboardButton>
                <OnboardButton
                  disabled={isSaving}
                  className="py-4 px-10"
                  hideArrow={true}
                  onClick={handleSubmit}
                >
                  {isSaving ? 'Saving...' : 'Add prompt'}
                </OnboardButton>
              </Stack>
            </>
          </div>
        </form>
      )}
    </Dialog>
  );
};
